import React, { useEffect, useState } from "react";
import { checkoff, checkon } from "../../../../assets/images";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import SystemModal from "../../../components/common/SystemModal";
import axios from "axios";
import { UserInfoResult } from "../../../../recoil/recoilState";
import { useRecoilState } from "recoil";
import LoadingScreen from "../../../components/TouchOll/join/LoadingScreen";
import LoginTopTxt from "../../../components/TouchOll/join/LoginTopTxt";
import PointIcon from "../../../components/TouchOll/join/PointIcon";
import StampIcon from "../../../components/TouchOll/join/StampIcon";

const Login = () => {
  console.log('?????')
  const [mobile, setMobile] = useState("");
  const [pw, setPw] = useState("");
  const [type, setType] = useState("U");
  const [userToken, setUserToken] = useState("");

  const [popupTxt, setPopupTxt] = useState(""); // 시스템 팝업 텍스트
  const [system, setSystem] = useState(false); // 시스템 팝업

  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false); // 모바일 기기인지 확인
  const [autoLogin, setAutoLogin] = useState(true); // 자동로그인 체크박스 상태

  const [loginUserInfo, setLoginUserInfo] = useRecoilState(UserInfoResult);

  /**
   * 모바일 기기 확인
   * isMobile : true (모바일 기기)
   */
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(
        userAgent
      );
    setIsMobile(isMobileDevice);
  }, []);

  /**
   * loginUserToken 존재 유무에 따라 메인 페이지 이동
   * 현재 0.5초
   */
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loginUserToken = localStorage.getItem("Usertokenkey");

    if (loginUserToken) {
      setLoading(true);
      if (isMobile && loginUserToken) {
        // eslint-disable-next-line no-undef
        userLoginToken.postMessage(loginUserToken);
      }

      // navigate("/app/main");
      const timeout = setTimeout(() => {
        navigate("/app/main");
        setLoading(false);
      }, 500);

      // cleanup 함수에서 타이머 해제
      return () => clearTimeout(timeout);
    }
  });

  /**
   * 로그인 로직
   * mobile, pw, type으로 api(/api/userLogin) 호출
   * >> 성공
   * 1. loginUserInfo 에 데이터 저장
   *    > userLoginToken 채널로 flutter 호출 (access token)
   * 2. FCM token : 'N' 일 때,
   *    > userFCMtoken 채널로 flutter 호출
   *    > flutter 에서 getFCMtoken 함수 호출
   *    > getFCMtokenAPI() 호출 > '/api/memberMngr/updteFCMToken'
   */
  const handleLogin = async () => {
    const url = "/api/userLogin";
    const data = {
      mobile: mobile,
      pw: pw,
      type: type,
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = response.data;
      const token = result?.data?.message?.token || ""; // 토큰 값 가져오기
      const refreshtoken = result?.data?.message?.refreshToken || ""; // 토큰 값 가져오기

      // window.getFCMtoken 함수로 해서 서버에 보내기
      const FCMtoken = result?.data?.message?.FCMtoken || ""; // FCM 토큰 여부 'Y'|'N'

      if (response.status === 200) {
        console.log("로그인 성공", result);
        // 로그인 성공, 페이지 이동
        navigate("/app/main", { replace: true });

        setLoginUserInfo({
          isAdmin: result?.data?.message?.isAdmin,
          agencyAt: result?.data?.message?.agencyAt,
          managerAt: result?.data?.message?.managerAt,
          userAt: result?.data?.message?.userAt,
          listStore: result?.data?.message?.listStore,
          name: result?.data?.message?.name,
          mobile: result?.data?.message?.mobile,
          birth: result?.data?.message?.birth,
          mngrLvl: result?.data?.message?.mngrLvl,
          userId: result?.data?.message?.userId,
          token: result?.data?.message?.token,
          refreshToken: result?.data?.message?.refreshToken,
        });

        if (isMobile && token) {
          // access token >> flutter 채널 호출
          // eslint-disable-next-line no-undef
          userLoginToken.postMessage(token);

          // flutter 채널 형성 하기
          // eslint-disable-next-line no-undef
          getDeviceSettingTelegram.postMessage("");
          // eslint-disable-next-line no-undef
          userApprovalPay.postMessage("");
          // eslint-disable-next-line no-undef
          getSettingJeonmun.postMessage("");
          // eslint-disable-next-line no-undef
          IOSFluttingBtn.postMessage("");
          // eslint-disable-next-line no-undef
          LocationManager.postMessage("");

          // eslint-disable-next-line no-undef
          // cancleNFCBtn.postMessage('');
        }

        localStorage.setItem("Usertokenkey", token);

        if (autoLogin) {
          localStorage.setItem("UserRefreshtokenkey", refreshtoken);
        }

        // FCMtoken 로직
        if (isMobile && FCMtoken === "N") {
          // eslint-disable-next-line no-undef
          userFCMtoken.postMessage("");
        }

        // 헤더에 Bearer 토큰 추가
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      } else {
        // 요청 실패
        setPopupTxt("아이디와 비밀번호를 확인해보세요.");
        setSystem(true);
      }
    } catch (error) {
      console.log("요청 중 오류가 발생했습니다.", error);
      setPopupTxt(
        error.response.data.message || "아이디와 비밀번호를 확인해보세요."
      );
      setSystem(true);
    }
  };

  const onCheckEnter = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  /**
   * 로그인 전화번호 유효성 검사
   */
  const isMobileValid = !mobile.match(
    /^(01[016789]{1})[1-9]{1}[0-9]{2,3}[0-9]{4}$/
  );

  /**
   * FCMtoken 이 'N' 일 때,
   * userFCMtoken 으로 flutter 채널 형성 및 호출
   * getFCMtoken 으로 react 함수 실행
   */
  window.getFCMtoken = (msg) => {
    // const key = msg.split("=")[0];
    const value = msg.split("=")[1].replace(/[^a-zA-Z0-9]/g, "");

    getFCMtokenAPI(value);
  };

  const getFCMtokenAPI = (jsonValue) => {
    const fetchFCMtoken = async () => {
      const data = {
        fcmToken: jsonValue,
      };

      try {
        const response = await axios.post(
          "/api/memberMngr/updteFCMToken",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.error("Error getFCMtoken data:", error);
      }
    };

    // data?.result === 'true' 이면, 정상 처리.
    fetchFCMtoken();
  };

  return (
    <>
      {loading && (
        // 자동 로그인 시, 로딩 페이지
        <LoadingScreen />
      )}

      <div id="sub_common_wrap">
        {
          /**
           * 시스템 모달 팝업 창
           */
          system && (
            <SystemModal system={system} setSystem={setSystem}>
              {popupTxt}
            </SystemModal>
          )
        }

        {/* 진짜 현금 보너스 터치올 */}
        <LoginTopTxt />

        <LoginInputWrap onKeyDown={onCheckEnter}>
          <ul>
            <li>
              <p>전화번호</p>
              <input
                placeholder=" - 를 제외하고 입력해주세요."
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                type="number"
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                }}
                maxLength={11}
                pattern="^(01[016789]{1})[1-9]{1}[0-9]{2,3}[0-9]{4}$"
              />
            </li>
            <li>
              <p>비밀번호</p>
              <input
                type="password"
                value={pw}
                onChange={(e) => setPw(e.target.value)}
              />
            </li>
          </ul>

          <label className={autoLogin ? "checkbox_on" : ""}>
            <input
              type="checkbox"
              checked={autoLogin}
              onChange={(e) => setAutoLogin(e.target.checked)}
            />
            <span></span>
            자동 로그인하기
          </label>

          <LoginBtns>
            <li>
              <div
                className={isMobileValid ? "disabled" : ""}
                disabled={isMobileValid}
                onClick={isMobileValid ? null : handleLogin}
              >
                로그인
              </div>
            </li>

            <li>
              <Link to="/app/pw_reissue">
                <div>비밀번호 재발급</div>
              </Link>
            </li>
          </LoginBtns>

          {/* <NomemberBtn>
          비회원으로 시작하기
        </NomemberBtn> */}
        </LoginInputWrap>

        <EtcBtn>
          <div>
            <Link to="/app/join_agree">원클릭 회원가입</Link>
          </div>
          <div>
            <Link to="/app/main">앱 둘러보기</Link>
          </div>
          <span>
            클릭 1번만으로 1분 - 뚝딱 가입<em className="it_txt">!</em>
          </span>
        </EtcBtn>

        <NoticeText>
          <div>
            <h4>* 클릭 1번 - 간편가입 직후부터</h4>
            <p>
              여기 오시기 전, <br />
              <PointIcon />, <StampIcon /> 등 보너스를 <br />폰 No 입력 · 임시
              수령했던 분은
            </p>

            <ol>
              <li>→ 이전 수령 보너스 확인은 물론</li>
              <li>
                → 폰 No 입력없이, 폰 터치 즉시<em className="it_txt">!</em>
              </li>
              <li>
                → <PointIcon /> 입금, <StampIcon /> 즉시 확인
                <em className="it_txt">!</em>
              </li>
              <li>
                → 신박한 전세계 최-초간편 <em className="pay">Pay</em> 시작
                <em className="it_txt">!</em>
              </li>
            </ol>
          </div>
        </NoticeText>
      </div>
    </>
  );
};

const LoginInputWrap = styled.form`
  width: calc(100% - 64px);
  background: #fff;

  padding: 24px 16px;
  border-radius: 16px;

  margin: 24px auto 0 auto;

  max-width: 600px;

  ul li {
    margin-bottom: 20px;

    p {
      font-size: 16px;
      margin-bottom: 8px;
    }

    input {
      height: 36px;
      font-size: 14px;
    }
  }

  input {
    width: calc(100% - 16px);
    background: #f3f3f3;
    border-radius: 5px;

    border: none;
    padding: 0 8px;

    color: #222;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    font-size: 16px;
    color: #222;
    margin-bottom: 20px;

    input[type="checkbox"] {
      display: none;
    }

    /* 체크박스 커스텀 이미지 적용 */
    input[type="checkbox"] + span {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url(${checkoff});
      background-size: cover;
    }

    /* 체크된 상태의 이미지 적용 */
    input[type="checkbox"]:checked + span {
      background-image: url(${checkon});
    }

    &.checkbox_on {
      color: #fe7a36;
    }
  }
`;

const LoginBtns = styled.ol`
  display: flex;
  justify-content: start-flex;
  align-items: center;
  gap: 12px;

  li {
    width: 100%;
    height: 40px;

    div {
      width: 100%;
      height: 40px;

      background: #555;
      color: #fff;

      font-size: 16px;
      border-radius: 5px;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.disabled {
        background: #aaa;
        cursor: default;
      }
    }

    &:last-child {
      width: 60%;

      div {
        width: 100%;
        background: #faefe9;
        color: #222;
        font-size: 14px;
      }
    }
  }
`;

const NomemberBtn = styled.button`
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 8px;
  font-size: 14px;
  border-radius: 5px;
  color: #888;
  // background-color: #eee;
`;

const EtcBtn = styled.div`
  width: calc(100% - 32px);
  margin: 24px auto 0 auto;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  max-width: 600px;

  div {
    width: 60%;
    position: relative;

    a {
      width: 100%;
      height: 36px;

      border-radius: 5px;
      background: #faefe9;

      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 14px;
      color: #333;
    }

    &:first-child {
      width: 100%;

      a {
        background: #fe7a36;
        color: #fff;
        font-size: 16px;
      }
    }
  }

  span {
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 28px;
    background: #5d0ef5;
    border-radius: 4px;

    font-size: 16px;
    color: #fff;

    bottom: -40px;
    left: 0;

    animation: updown 0.4s alternate infinite;

    em {
      color: #fff;
    }
  }
  span:after {
    border-top: 0px solid transparent;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid #5d0ef5;
    content: "";
    position: absolute;
    top: -5px;
    left: 30%;
    margin-left: -2px;
  }

  @keyframes updown {
    form {
      bottom: -50px;
    }
    to {
      bottom: -36px;
    }
  }
`;

const NoticeText = styled.div`
  width: calc(100% - 32px);
  padding: 0 16px 30px 16px;

  max-width: 600px;
  margin: 50px auto 0 auto;

  div {
    width: calc(100% - 32px);
    padding: 24px 16px;
    background: #f8f5f3;
    border: 2px solid #f8d8c5;
    border-radius: 16px;
    margin-top: 24px;

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 16px;
      color: #ff5f01;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      color: #5d0ef5;
      margin-bottom: 16px;
      line-height: 22px;

      img {
        width: 14px !important;
      }
    }

    ol {
      font-size: 14px;
      color: #8e3d82;

      li {
        line-height: 22px;
        color: #8e3d82;

        .it_txt {
          color: #8e3d82;
        }
        .pay {
          color: #fe7a36;
        }

        img {
          width: 14px !important;
        }

        &:nth-child(2) {
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export default Login;
